/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OfferForListType } from '../../types';
import { OfferMessageType } from '../../types/OfferMessageType';
import { OfferMessageForListType } from '../../types/OfferMessageForListType';

type OffersStateType = {
    all: OfferForListType[];
    fetchingAll: boolean;
    byTenantId: {[key: number | string]: OfferForListType[] };
    fetchingByTenantId: {[key: number | string]: boolean}
    byOrderId: {[key: number | string]: OfferForListType[] };
    fetchingByOrderId: {[key: number | string]: boolean}
    messagesById: { [key: number | string]: OfferMessageType[] };
    fetchingMessagesById: { [key: number | string]: boolean };
    allMessages: OfferMessageForListType[];
    fetchingAllMessages: boolean;
};

const initialState: OffersStateType = {
    all: [],
    fetchingAll: false,
    byTenantId: {},
    fetchingByTenantId: {},
    byOrderId: {},
    fetchingByOrderId: {},
    messagesById: {},
    fetchingMessagesById: {},
    allMessages: [],
    fetchingAllMessages: false,
};

const slice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: OffersStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
        fetchByTenantId: (state, action) => {
            state.fetchingByTenantId[action.payload.id] = true;
        },
        byTenantIdFetched: (state, action: PayloadAction<{ id: number | string, data: OfferForListType[] }>) => {
            state.byTenantId[action.payload.id] = action.payload.data;
            state.fetchingByTenantId[action.payload.id] = false;
        },
        fetchByOrderId: (state, action) => {
            state.fetchingByOrderId[action.payload.id] = true;
        },
        byOrderIdFetched: (state, action: PayloadAction<{ id: number | string, data: OfferForListType[] }>) => {
            state.byOrderId[action.payload.id] = action.payload.data;
            state.fetchingByOrderId[action.payload.id] = false;
        },
        fetchMessagesById: (state, action) => {
            state.fetchingMessagesById[action.payload.id] = true;
        },
        messagesByIdFetched: (state, action: PayloadAction<{ id: number | string, data?: OfferMessageType[] }>) => {
            state.messagesById[action.payload.id] = action.payload.data || [];
            state.fetchingMessagesById[action.payload.id] = false;
        },
        fetchAllMessages: (state) => {
            state.fetchingAllMessages = true;
        },
        allMessagesFetched: (state, action: PayloadAction<{data: OffersStateType['allMessages']}>) => {
            state.allMessages = action.payload.data;
            state.fetchingAllMessages = false;
        }
    }
});

export default slice;

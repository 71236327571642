import React from 'react';

import {
    Logout,
    Business,
    Groups,
    People,
    Notifications,
    LocalShipping,
    LocalOffer,
    Mail,
    Feedback,
    Insights,
    AdminPanelSettings,
    TouchApp,
    Chat
} from '@mui/icons-material';

import logout from '../../utils/logout';

type LinkType = {
    name: string,
    icon: JSX.Element,
    path?: string,
    action?: () => void,
    key?: string,
    links?: LinkType[],
    isAdmin?: boolean
};

export default function getMenuLinks({ canReadStats }: { canReadStats: boolean }): LinkType[] {
    const menuLinks = [{
        name: 'Företag',
        icon: <Business />,
        path: '/tenants'
    }, {
        name: 'Användare',
        icon: <People />,
        path: '/users'
    }, {
        name: 'Grupper',
        icon: <Groups />,
        path: '/groups'
    }, {
        name: 'Bevakningar',
        icon: <Notifications />,
        path: '/watchlists'
    }, {
        name: 'Uppdrag',
        icon: <LocalShipping />,
        path: '/orders'
    }, {
        name: 'Anbud',
        icon: <LocalOffer />,
        path: '/offers'
    }, {
        name: 'Meddelanden',
        icon: <Chat />,
        path: '/offer-messages'
    }, {
        name: 'Inbjudningar',
        icon: <Mail />,
        path: '/invites'
    }, {
        name: 'Feedback',
        icon: <Feedback />,
        path: '/feedback'
    }, {
        name: 'Aktiviteter',
        icon: <TouchApp />,
        path: '/requests'
    }, {
        name: 'Statistik',
        icon: <Insights />,
        key: 'admin',
        path: '/stats',
        canReadStats: true
    }, {
        name: 'Administratörer',
        icon: <AdminPanelSettings />,
        path: '/admins'
    }, {
        name: 'Logga ut',
        icon: <Logout />,
        action: logout
    }];

    return canReadStats ? menuLinks : menuLinks.filter((l) => !l.canReadStats);
}

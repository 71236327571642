import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchOffersByTenantId,
    selectFetchingOffersByTenantId,
    selectOffersByTenantId
} from '../../redux/actions/offers';
import { OfferForListType } from '../../types';
import { formatOfferStatus } from '../../utils/parsing';
import { getOfferStatus } from '../../utils/status';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

const columns = [{
    name: 'Uppdrag',
    key: 'orderId',
    canSearch: true
}, {
    name: 'Uppdragsgivare',
    key: 'orderTenantName',
    canSearch: true
}, {
    name: 'Användare',
    key: 'createdByName',
    canSearch: true
}, {
    name: 'Önskat pris [SEK]',
    key: 'price',
    type: 'number' as 'number'
}, {
    name: 'Meddelande',
    key: 'message'
}, {
    name: 'Svara senast',
    key: 'expiresAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}, {
    name: 'Meddelanden',
    key: 'messageCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Status',
    key: 'customStatus',
    getValue: (o: any) => formatOfferStatus(getOfferStatus(o)),
    canSearch: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

function TenantOffers(props: FetchPropsType<OfferForListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();

    const onRowClick = React.useCallback((offer: { orderId: number }) => {
        navigate(`/orders/${offer.orderId}`);
    }, [navigate]);

    return (
        <Table
            name="tenantOffers"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar anbud...' : 'Det finns inga anbud.'}
            showPagination
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
        />
    );
}

export default function TenanOffersFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<OfferForListType[]>
            fetchEntity={fetchOffersByTenantId(id)}
            selectEntity={selectOffersByTenantId(id)}
            selectIsFetching={selectFetchingOffersByTenantId(id)}
            Component={TenantOffers}
            name="anbud"
            initialFetch={!hasRendered}
        />
    );
}

import React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from '@mui/material';
import { Close } from '@mui/icons-material';

import OfferChat from './OfferChat';

type PropsType = {
    open: boolean;
    id?: number;
    offerTenantId?: number;
    recipient?: string;
    close: () => void;
}

export default function OfferChatPopup({
    open,
    id,
    offerTenantId,
    recipient,
    close
}: PropsType) {
    return (
        <Dialog
            open={open}
            onClose={close}
            PaperProps={{ sx: { minWidth: '500px' } }}
        >
            <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                <IconButton onClick={close}>
                    <Close />
                </IconButton>
            </Box>
            <DialogTitle>
                {recipient}
            </DialogTitle>
            <DialogContent>
                <OfferChat
                    id={id}
                    offerTenantId={offerTenantId}
                    maxHeight="800px"
                />
            </DialogContent>
        </Dialog>
    );
}

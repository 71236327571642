import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { NotFound } from '../CommonComponents';

import Layout from '../Layout/Layout';

import Admins from '../Admins/Admins';

import Dashboard from '../Dashboard/Dashboard';

import Feedback from '../Feedback/Feedback';

import Groups from '../Groups/Groups';
import GroupTabs from '../Groups/GroupTabs';
import GroupForm from '../Groups/GroupForm';

import Invites from '../Invites/Invites';

import Orders from '../Orders/Orders';

import Offers from '../Offers/Offers';

import Requests from '../Requests/Requests';

import Tenants from '../Tenants/Tenants';
import TenantTabs from '../Tenants/TenantTabs';
import TenantForm from '../Tenants/TenantForm';

import Users from '../Users/Users';
import UserTabs from '../Users/UserTabs';
import UserForm from '../Users/UserForm';

import OrderTabs from '../Orders/OrderTabs';

import StatsTabs from '../Stats/StatsTabs';
import Watchlists from '../Watchlists/Watchlists';
import WatchlistDetails from '../Watchlists/WatchlistDetails';
import OfferMessages from '../OfferMessages/OfferMessages';

export default function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Dashboard />} />

                    <Route path="/tenants" element={<Tenants />} />
                    <Route path="/tenants/:id" element={<TenantTabs />} />
                    <Route path="/tenants/create" element={<TenantForm />} />
                    <Route path="/tenants/edit/:id" element={<TenantForm />} />

                    <Route path="/users" element={<Users />} />
                    <Route path="/users/:id" element={<UserTabs />} />
                    <Route path="/users/create" element={<UserForm />} />
                    <Route path="/tenants/:tenantId/users/create" element={<UserForm />} />
                    <Route path="/users/edit/:id" element={<UserForm />} />

                    <Route path="/groups" element={<Groups />} />
                    <Route path="/groups/:id" element={<GroupTabs />} />
                    <Route path="/groups/create" element={<GroupForm />} />
                    <Route path="/tenants/:tenantId/groups/create" element={<GroupForm />} />
                    <Route path="/groups/edit/:id" element={<GroupForm />} />

                    <Route path="/watchlists" element={<Watchlists />} />
                    <Route path="/watchlists/:id" element={<WatchlistDetails />} />

                    <Route path="/orders" element={<Orders />} />
                    <Route path="/orders/:id" element={<OrderTabs />} />

                    <Route path="/offers" element={<Offers />} />

                    <Route path="/offer-messages" element={<OfferMessages />} />

                    <Route path="/invites" element={<Invites />} />

                    <Route path="/feedback" element={<Feedback />} />

                    <Route path="/requests" element={<Requests />} />

                    <Route path="/stats" element={<StatsTabs />} />

                    <Route path="/admins" element={<Admins />} />

                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

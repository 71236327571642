import React from 'react';
import { Button } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { copyStringToClipboard } from '../../utils/clipboard';

type PropsType = {
    getData: () => Promise<any>;
    getCount: () => Promise<any>;
};

export default function CopyHtmlButton({ getData, getCount }: PropsType) {
    const copy = React.useCallback(() => {
        copyStringToClipboard(getData, getCount);
    }, [getData, getCount]);

    return (
        <Button variant="outlined" startIcon={<ContentCopy />} onClick={copy}>
            HTML
        </Button>
    );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    fetchAllOffers,
    selectFetchingAllOffers,
    selectAllOffers
} from '../../redux/actions/offers';
import { useDispatch, useSelector } from '../../redux/hooks';
import { formatOfferStatus } from '../../utils/parsing';
import { getOfferStatus } from '../../utils/status';
import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

const columns = [{
    name: 'Uppdrag',
    key: 'orderId',
    canSearch: true
}, {
    name: 'Uppdragsgivare',
    key: 'orderTenantName',
    canSearch: true
}, {
    name: 'Företag',
    key: 'tenantName',
    canSearch: true
}, {
    name: 'Användare',
    key: 'createdByName',
    canSearch: true
}, {
    name: 'Önskat pris [SEK]',
    key: 'price',
    type: 'number' as 'number'
}, {
    name: 'Meddelande',
    key: 'message',
    canSearch: true
}, {
    name: 'Svara senast',
    key: 'expiresAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}, {
    name: 'Meddelanden',
    key: 'messageCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Status',
    key: 'status',
    getValue: (o: any) => formatOfferStatus(getOfferStatus(o)),
    canSearch: true,
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

export default function Offers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const offers = useSelector(selectAllOffers());
    const isFetching = useSelector(selectFetchingAllOffers());

    React.useEffect(() => {
        dispatch(fetchAllOffers());
    }, [dispatch]);

    const onRowClick = React.useCallback((offer: { orderId: number }) => {
        navigate(`/orders/${offer.orderId}`);
    }, [navigate]);

    return (
        <Container>
            <Header title="Anbud" />

            <Paper padding={0}>
                <Table
                    name="offers"
                    columns={columns}
                    data={offers}
                    onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar anbud...' : 'Det finns inga anbud.'}
                    showPagination
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                />
            </Paper>
        </Container>
    );
}

import React from 'react';
import { Box } from '@mui/material';

import OfferChatMessage from './OfferChatMessage';
import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchOfferMessagesById, selectOfferMessagesById } from '../../redux/actions/offers';
import { InfoText } from '../CommonComponents';

type PropsType = {
    id?: number,
    offerTenantId?: number,
    maxHeight?: string
}

const FETCH_INTERVAL = 60 * 1000; // 60 seconds

export default function OfferChat({
    id,
    offerTenantId,
    maxHeight
}: PropsType) {
    const dispatch = useDispatch();

    const messageContainerRef = React.useRef<HTMLDivElement>();
    const messages = useSelector(selectOfferMessagesById(id)) || [];

    React.useEffect(() => {
        if (id) {
            dispatch(fetchOfferMessagesById(id));

            const intervalId = window.setInterval(() => {
                dispatch(fetchOfferMessagesById(id));
            }, FETCH_INTERVAL);

            return () => window.clearInterval(intervalId); // Runs before every effect-run, and on unmount
        }

        return () => { };
    }, [dispatch, id]);

    React.useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [messages?.length]);

    return (
        <Box
            ref={messageContainerRef}
            sx={{ maxHeight, overflowY: 'auto', marginBottom: 1 }}
        >
            {messages?.length === 0 && (
                <InfoText>
                    Här finns inga meddelanden!
                </InfoText>
            )}
            {messages?.map((message) => (
                <OfferChatMessage
                    key={message.id}
                    isCurrentTenant={message.createdByTenantId !== offerTenantId}
                    offerTenantId={offerTenantId}
                    userName={`${message.createdByUserName}, ${message.createdByTenantName}`}
                    createdAt={message.createdAt}
                    message={message.message}
                    readBy={message.readBy}
                />
            ))}
        </Box>
    );
}

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchOffersByOrderId,
    selectFetchingOffersByOrderId,
    selectOffersByOrderId
} from '../../redux/actions/offers';
import { OfferForListType } from '../../types';
import { formatOfferStatus } from '../../utils/parsing';
import { getOfferStatus } from '../../utils/status';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import OfferChatPopup from '../Offers/OfferChatPopup';

const columns = [{
    name: 'Företag',
    key: 'tenantName',
    canSearch: true
}, {
    name: 'Användare',
    key: 'createdByName',
    canSearch: true
}, {
    name: 'Önskat pris [SEK]',
    key: 'price',
    type: 'number' as 'number'
}, {
    name: 'Meddelande',
    key: 'message'
}, {
    name: 'Svara senast',
    key: 'expiresAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}, {
    name: 'Meddelanden',
    key: 'messageCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Status',
    key: 'customStatus',
    getValue: (o: any) => formatOfferStatus(getOfferStatus(o)),
    canSearch: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

function OrderOffers(props: FetchPropsType<OfferForListType[]>) {
    const { entity, isFetching } = props;

    const [offerForMessages, setOfferForMessages] = React.useState<OfferForListType | null>(null);

    const closeOfferChat = React.useCallback(() => {
        setOfferForMessages(null);
    }, []);

    const onRowClick = React.useCallback((offer: OfferForListType) => {
        setOfferForMessages(offer);
    }, []);

    return (
        <>
            <OfferChatPopup
                open={!!offerForMessages}
                id={offerForMessages?.id}
                offerTenantId={offerForMessages?.tenantId}
                recipient={offerForMessages?.tenantName}
                close={closeOfferChat}
            />
            <Table
                name="orderOffers"
                columns={columns}
                data={entity}
                onRowClick={onRowClick}
                emptyText={isFetching ? 'Hämtar anbud...' : 'Det finns inga anbud.'}
                showPagination
                defaultOrderBy="createdAt"
                defaultOrderDirection="asc"
            />
        </>
    );
}

export default function OrderDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<OfferForListType[]>
            fetchEntity={fetchOffersByOrderId(id)}
            selectEntity={selectOffersByOrderId(id)}
            selectIsFetching={selectFetchingOffersByOrderId(id)}
            Component={OrderOffers}
            name="anbud"
            initialFetch={!hasRendered}
        />
    );
}

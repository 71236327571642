/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardDataType } from '../../types';

type DashboardsStateType = {
    data: DashboardDataType,
    fetchingData: boolean
};

const initialState: DashboardsStateType = {
    data: {
        pendingRegistrations: null,
        activeUsersToday: null,
        acceptedTenants: null,
        acceptedUsers: null,
        activeOrders: null,
        activeOffers: null,
        groups: null,
        watchlistsWithNotifications: null,
        unhandledInvites: null,
        unhandledFeedback: null,
        offerMessages: null
    },
    fetchingData: false
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        fetchData: (state) => {
            state.fetchingData = true;
        },
        dataFetched: (state, action: PayloadAction<{data: DashboardsStateType['data']}>) => {
            state.data = action.payload.data;
            state.fetchingData = false;
        },
        resetData: (state) => {
            state.data = initialState.data;
            state.fetchingData = false;
        }
    }
});

export default slice;

import toast from './toast';

// eslint-disable-next-line import/prefer-default-export
export async function copyStringToClipboard(getData: () => Promise<any>, getCount: () => Promise<any>) {
    const space = '\u00A0\u00A0\u00A0';

    try {
        const { count } = await getCount();

        if (count === 0) {
            toast(`❌ ${space} Inga uppdrag att hämta...`);
        } else {
            navigator.clipboard.writeText(await getData());
            toast(`✅ ${space} HTML för ${count} uppdrag koperade till urklipp`);
        }
    } catch (e) {
        if (e instanceof Error) {
            // eslint-disable-next-line no-console
            console.log(`${e.name}: ${e.message}`);
        }
        toast(e, `❌ ${space} Kunde inte kopiera html, försök igen...`);
    }
}
